.SectionTitle {
  font-size: 42px;
  line-height: 52px;
  text-align: center;
  font-weight: 600;
  text-align: left;
  &::after {
    content: '';
    display: block;
    width: 48px;
    height: 4px;
    background-color: var(--accent);
    border-radius: 4px;
    margin-top: 8px; } }

@media screen and ( max-width: 767px ) {
  .SectionTitle {
    font-size: 36px;
    line-height: 46px; } }
