.Site-Hero {
  width: calc(100vw - 96px);
  margin-left: 48px;
  border-radius: 16px;
  height: 450px;
  background-size: cover;
  background-position: top center;
  position: relative;
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 16px;
    position: absolute; }
  .container {
    height: 100%; }
  .Offer-Container {
    display: flex;
    align-items: center;
    height: 100%; }
  .Offer {
    color: #fff;
    display: grid;
    grid-gap: 24px;
    .Offer-Title {
      font-size: 52px;
      font-weight: 700;
      line-height: 62px;
      margin-bottom: 0;
      span {
        font-weight: 500; } } } }

@media screen and ( max-width: 1200px ) {
  .Site-Hero {
    width: calc(100vw - 48px);
    margin-left: 24px;
    height: 350px;
    .Offer {
      .Offer-Title {
        font-size: 42px;
        line-height: 52px; } } } }
@media screen and ( max-width: 991px ) {
  .Site-Hero {
    .Offer {
      .Offer-Title {
        font-size: 32px;
        line-height: 42px; } } } }
@media screen and ( max-width: 767px ) {
  .Site-Hero {
    .Offer {
      .Offer-Title {
        br {
          display: none; } } } } }

@media screen and ( max-width: 575px ) {
  .Site-Hero {
    width: 100%;
    margin-left: 0;
    border-radius: 0px !important;
    .Offer {
      justify-items: center;
      .Offer-Title {
        text-align: center; } } } }
