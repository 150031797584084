.Site-Nav {
  width: 100%;
  height: 64px;
  background-color: #fff;
  padding: 0px 48px;
  display: grid;
  grid-template-columns: max-content 1fr repeat(2, max-content);
  grid-gap: 32px;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
  transition: all 0.4s ease;
  .Logo {
    flex-shrink: 0; } }
.Site-Nav_theme_shadow {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.07); }
.Site-Nav-Menu {
  display: grid;
  grid-template-columns: repeat(7, max-content);
  grid-gap: 24px;
  align-items: center;
  justify-self: end; }

.Site-NavLink {
  color: #000;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  &:hover {
    color: var(--accent); }
  svg {
    transition: all 0.3s ease; } }
.Site-NavLink_theme_active {
  color: var(--accent); }

.Site-NavLink-Arrow {
  font-size: 14px;
  color: var(--descriptionDark);
  margin-left: 8px; }
.Site-Nav-Controlls {
  display: grid;
  grid-template-columns: repeat(4, max-content);
  grid-gap: 40px;
  align-items: center;
  justify-self: end;
  .Site-NavLink {
    padding: 0; } }
.CallUsBar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  .Icon {
    color: var(--accent);
    font-size: 18px;
    margin-right: 8px;
    transform: rotate(-45deg);
    margin-top: 4px; } }
.CallUsBar-Description {
  color: var(--darkGray); }
.CallUsBar-PhoneNumber {
  font-weight: 500;
  color: #000;
  font-size: 16px;
  display: flex;
  // align-items: t
  &:hover {
    color: var(--darkGray); } }

@media screen and ( max-width:  1200px) {
  .Site-Nav {
    padding: 0px 24px;
    grid-template-columns: max-content 1fr max-content;
    grid-gap: 24px; }
  .Site-Hamburger-Container {
    flex-shrink: 0; }
  .Site-Nav-Menu {
    display: none;
    position: absolute;
    top: 64px;
    left: 0px;
    grid-template-columns: 1fr;
    width: 100%;
    background-color: #fff;
    padding: 16px 24px;
    overflow-y: auto;
    max-height: calc(100vh - 64px);
    height: auto;
    grid-template-rows: repeat(4, minmax(0, max-content));
    .Site-NavLink {
      width: 100%;
      justify-content: space-between;
      border-bottom: 1px var(--descriptionLight) solid;
      font-size: 18px;
      padding: 0px;
      &:last-child {
        border-bottom: 0; } }
    .Site-NavLink_theme_active {
      color: var(--accent); } } }

// @media screen and ( max-width:  767px)
//   .Site-Nav-Controlls
//     grid-template-columns: max-content
//     .Site-NavLink, .TryFreeButton, .LangPicker
//       display: none
//   .Site-Nav-Menu
//     .Site-DropdownMenu-Container
//       grid-template-columns: repeat(2, minmax(0, 1fr))
// @media screen and ( max-width:  575px)
//   .Site-Nav-Menu
//     .Site-DropdownMenu-Container
//       grid-template-columns: 1fr
