.About {}

.About-Container {
  margin-top: 64px;
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-gap: 64px; }
.About-Image {
  width: 400px;
  height: 350px;
  object-fit: cover;
  border-radius: 16px; }
.About-Info {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  align-self: start;
  .Title {
    margin-bottom: 0;
    font-size: 18px; }
  .Description {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;
    color: var(--darkGray);
    .Description-Facts {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 24px; } } }

@media screen and ( max-width: 991px ) {
  .About-Container {
    grid-template-columns: 1fr; }
  .About-Image {
    width: 100%; } }
@media screen and ( max-width: 575px ) {
  .About-Container {
    margin-top: 48px;
    grid-gap: 48px; }
  .About-Image {
    height: 280px; }
  .About-Info {
    .Description {
      .Description-Facts {
        grid-template-columns: 1fr; } } } }
