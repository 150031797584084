.ContactUs {
  background-color: var(--lightGray); }
.ContactUs-Container {
  margin-top: 64px;
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 64px;
  .ContactUs-Links {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 24px; }
  .SocialBlock {
    font-size: 16px;
    a {
      &:not(:last-child) {
        margin-right: 12px; } } }
  .ContactUs-Map {
    margin-top: 64px;
    .Map {
      height: 350px; } } }

.ContactUsInfoTile {
  display: flex;
  align-items: center;
  .ContactUsInfoTile-Icon {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 16px;
    background-color: var(--accent);
    margin-right: 12px;
    border-radius: 16px; }
  .ContactUsInfoTile-Data {
    font-size: 16px;
    a {
      color: #000; }
    .Description {
      font-size: 14px;
      color: var(--darkGray); } } }
.Footer-SocialIcons {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-gap: 12px;
  font-size: 18px;
  .Viber {
    color: #9175f0 !important; }
  .Telegram {
    color: #2ba2de !important; }
  .Whatsapp {
    color: #1ad86c !important; } }

@media screen and ( max-width: 991px ) {
  .ContactUs-Container {
    grid-template-columns: 1fr;
    grid-gap: 32px;
    .ContactUs-Map {
      margin-top: 32px; }
    .ContactUs-Form {
      max-width: 100%; } } }
@media screen and ( max-width: 575px ) {
  .ContactUs-Container {
    margin-top: 48px;
    .ContactUs-Links {
      grid-template-columns: 1fr; }
    .ContactUs-Form {
      justify-items: center; } } }
