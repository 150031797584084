.ProductManager {
  max-width: 700px; }

.ProductManager-Form {
  padding: 32px 16px;
  background-color: var(--lightGray);
  margin-top: 32px;
  border-radius: 16px;
  display: grid;
  grid-template-columns: 180px 1fr;
  grid-gap: 24px;
  align-items: start;
  .Site-StatusMessage {
    grid-column: 1/-1;
    margin-bottom: 32px; }
  .Field_status_invalid {
    border-bottom: 2px var(--notyColor) solid; } }
.ProductManager-Fields {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px; }
.ProductManager-Buttons {
  margin-top: 32px;
  display: flex;
  .Button {
    &:not(:last-child) {
      margin-right: 24px; } } }

.EmptyPhoto {
  width: 180px;
  height: 130px;
  background-color: var(--accent);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  .Icon {
    font-size: 48px;
    color: #fff; } }
.ProductManager-ProductAvatar {
  position: relative;
  img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
    object-position: top center; }
  .Field {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0; } }
