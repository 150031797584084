.Directions {
  margin-top: -48px; }

.Directions-Container {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 32px; }
.DirectionTile {
  padding: 16px;
  background-color: #fff;
  display: grid;
  grid-template-columns: 64px 1fr;
  grid-gap: 16px;
  border-radius: 16px;
  box-shadow: var(--boxShadow);
  height: 100%;

  .DirectionTile-Icon {
    width: 64px;
    height: 64px;
    border-radius: 100%;
    object-fit: cover;
    object-position: top left; }
  .DirectionTile-Info {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    .Info-Title {
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 600;
      line-height: 26px;
      color: #000; }
    .Info-Description {
      color: var(--darkGray); } } }

@media screen and ( max-width: 1200px ) {
  .DirectionTile {
    grid-template-columns: 1fr;
    justify-items: center; } }

@media screen and ( max-width: 991px ) {
  .Directions-Container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 24px; }
  .DirectionTile {
    grid-template-columns: 64px 1fr; } }
@media screen and ( max-width: 767px ) {
  .Directions {
    margin-top: -24px; } }
@media screen and ( max-width: 575px) {
  .Directions-Container {
    grid-template-columns: 1fr; }
  .Directions {
    margin-top: 16px; } }
