
.Warehouse-Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 64px; }
.Step {
  width: calc(50% + 1px);
  padding-bottom: 32px;
  position: relative; }

.Step_theme_left {
  align-self: flex-start;
  border-right: 2px #d3d3d3 solid;
  padding-right: 32px;
  &::before {
    content: '';
    display: block;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    background-color: var(--accent);
    position: absolute;
    top: 0;
    right: -14px; }
  .Title, .Description {
    text-align: right; } }

.Step_theme_right {
  align-self: flex-end;
  border-left: 2px #d3d3d3 solid;
  padding-left: 32px;
  &::before {
    content: '';
    display: block;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    background-color: var(--accent);
    position: absolute;
    top: 0;
    left: -14px; } }

.Step-Container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  align-self: start; }
.Step-Image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 16px; }
.Step-Info {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  align-self: start;
  .Title {
    margin-bottom: 0;
    font-size: 18px;
    text-transform: uppercase; }
  .Description {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;
    color: var(--darkGray);
    font-size: 16px; } }

.Help-Container {
  display: flex;
  justify-content: center;
  margin-top: 64px; }

.Help-Step {
  display: grid;
  grid-template-columns: 64px 1fr;
  grid-gap: 24px;
  align-items: center;
  .Title {
    font-size: 18px; }
  &:not(:last-child) {
    margin-bottom: 24px; }
  img {
    width: 64px; } }

@media screen and ( max-width: 575px ) {
  .Step-Image {
    height: 150px; }
  .Step-Info {
    .Title {
      font-size: 16px; }
    .Description {
      font-size: 14px; } }
  .Help-Step {
    .Title {
      font-size: 16px; } } }

//   .About-Container
//     grid-template-columns: 1fr
//   .About-Image
//     width: 100%
// @media screen and ( max-width: 575px )
//   .About-Container
//     margin-top: 48px
//     grid-gap: 48px
//   .About-Image
//     height: 280px
//   .About-Info
//     .Description
//       .Description-Facts
//         grid-template-columns: 1fr
