.ContactUs-Form {
  width: 100%;
  max-width: 400px;
  color: #000;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  grid-template-rows: min-content;
  align-self: start;
  h5 {
    font-size: 24px;
    margin: 0;
    padding: 0;
    line-height: 24px; }
  p {
    font-size: 14px;
    color: var(--darkGray); }
  .Form-Fields {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px; }
  .ContactUsForm-Buttons {
    margin-top: 32px;
    display: inline-block; } }
