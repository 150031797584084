.SaltCatalog-Container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 32px;
  margin-top: 64px; }
.SaltTile {
  padding: 16px;
  border-radius: 16px;
  box-shadow: var(--boxShadow);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  text-align: center;
  .Title {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 16px; }
  img {
    width: 100%;
    height: 180px;
    object-fit: contain;
    object-position: top center; } }

@media screen and ( max-width: 991px ) {
  .SaltCatalog-Container {
    grid-template-columns: 1fr; }
  .SaltTile {
    grid-template-columns: 160px 1fr;
    text-align: left;
    img {
      height: 100px; } } }
@media screen and ( max-width: 575px ) {
  .SaltCatalog-Container {
    margin-top: 48px; }
  .SaltTile {
    grid-template-columns: 1fr;
    text-align: center; } }
