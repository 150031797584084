.Catalog-Container {
  display: grid;
  grid-template-columns: 280px 1fr;
  grid-gap: 32px;
  align-items: start;
  .SectionTitle {
    margin-bottom: 32px; } }

.Catalog-LeftBlock {
  position: sticky;
  left: 0;
  top: 96px; }
.Catalog-Nav {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  align-self: start; }

.CatalogNavItem {
  display: flex;
  font-size: 16px;
  text-transform: uppercase;
  color: var(--darkGray);
  cursor: pointer;
  transition: all 0.4s ease;
  display: flex;
  align-items: center;
  .CatalogNavItem-Marker {
    margin-right: 8px;
    font-size: 10px;
    color: #fff; }
  &:hover {
    color: var(--accent); }
  .CatalogNavItem-AddProduct {
    margin-left: 8px; } }
.CatalogNavItem_theme_active, .CatelogNavItem-Subcategory_theme_active {
  font-weight: 600;
  color: #000 !important;
  .CatalogNavItem-Marker {
    color: var(--accent); } }
.CatalogNavItem-Subcategories {
  padding-left: 18px;
  margin-top: 8px; }
.CatelogNavItem-Subcategory {
  color: var(--darkGray);
  cursor: pointer;
  transition: all 0.4s ease;
  &:hover {
    color: var(--accent); }
  &:not(:last-child) {
    margin-bottom: 8px; }
  .Icon {
    margin-left: 12px; } }
.Catalog-Content {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px; }

.ProductTile {
  width: 100%;
  padding: 16px;
  box-shadow: var(--boxShadow);
  border-radius: 16px;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 16px;
  justify-content: space-between;
  position: relative;
  .ManageProductButtons {
    position: absolute;
    top: 16px;
    right: 16px;
    .Button {
      text-transform: none;
      &:not(:last-child) {
        margin-right: 12px; } } }
  .Product-Info {
    display: grid;
    grid-template-columns: 120px 1fr;
    grid-gap: 16px; }
  .Product-Img {
    width: 120px;
    height: 100px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
      border-radius: 8px; } }
  .NoProductPhoto {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--lightGray);
    border-radius: 8px;
    font-size: 24px;
    color: var(--darkGray); }

  .Product-Info-Container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    align-self: start;
    .Title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0; }
    .DescriptionLine {
      color: var(--darkGray);
      span {
        color: #000; } }
    .DescriptionLine-Accent {
      span {
        color: var(--accent); } }
    .ProductDetailsText {
      color: #000;
      font-weight: 500;
      cursor: pointer; } }
  .Product-Details {
    display: grid;
    grid-template-columns: repeat(2, min-content);
    align-self: end;
    grid-gap: 16px; }
  .Detail {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;
    font-size: 14px;
    font-weight: 600;
    .Detail-Types {
      display: grid;
      grid-template-columns: repeat(2, max-content);
      grid-gap: 16px;
      span {
        background-color: var(--lightGray);
        color: var(--darkGray);
        padding: 8px 16px;
        border-radius: 16px; } } } }
.Catalog-Nav-Title {
  width: 100%;
  padding: 8px 16px;
  text-align: center;
  background-color: var(--accent);
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  display: none;
  transition: all 0.4s ease;
  .Icon {
    margin-left: 8px; } }
.EmptyContent {
  text-align: center;
  color: var(--darkGray); }
.ProductDetails-Tooltip {
  max-width: 350px;
  white-space: pre-line; }

@media screen and ( max-width: 1200px ) {
  .ProductTile {
    grid-template-columns: 1fr;
    .Product-Details {
      margin-left: 136px; } } }
@media screen and ( max-width: 991px ) {
  .Catalog-LeftBlock {
    position: relative;
    top: 0;
    left: 0; }

  .Catalog-Container {
    grid-template-columns: 1fr; }
  .Catalog-Nav-Title {
    display: block; }
  .Catalog-Nav {
    display: none;
    margin-top: 16px; }
  .Catalog-Container_showNav {
    transition: all 0.4s ease;
    .Catalog-Nav-Title {
      background-color: var(--accent);
      color: #fff;
      .Icon {
        transform: rotate(180deg); } }
    .Catalog-Nav {
      display: grid;
      background-color: var(--lightGray);
      padding: 16px;
      border-radius: 16px; } } }
@media screen and ( max-width: 575px ) {
  .ProductTile {
    .Product-Details {
      margin-left: 0;
      grid-template-columns: 1fr; } } }
